import './product_show.sass'
import pluralize from 'pluralize'

$(document).ready(() => {
  var addToCartForm = $('#new_order_item')[0]
  var quantityInput = $('#api_models_order_item_quantity')[0]
  var minimumQuantityMessageContainer = $('#minimum-quantity-message-container')[0]
  var allOfferingsMinimumQuantity = $($("#product-price")[0]).data('all-minimum-quantity')

  var init = function() {
    removeDescrptionUlMargins()
    $("#product-listings-more-matches-link").click(onShowMoreMatchesClick)
    // trigger input quantity change to calculate partial matches
    $(quantityInput).trigger('input')
  }

  var removeDescrptionUlMargins = function() {
    $(".product-description ul").prev("p").css("margin", "0")
  }

  var showCheckoutFlash = function() {
    $("#checkout-flash-container").removeClass("-invisible")

    setTimeout(() => {
      $("#checkout-flash-container").addClass("-invisible")
    }, 5000)
  }

  // handle Description Read More click
  $('.read-more-link').on('click', function(e) {
    e.preventDefault()
    var content = $(this).data("full-content")
    $(this).parent().html(content)
  });

  // disable add to cart buttons while scrolling
  if ($(".product-show-container").length > 0) {
    var scrollingElement = $(".product-show-container")[0].ownerDocument.scrollingElement
    var pageTopPosition = scrollingElement && scrollingElement.scrollTop;

    setInterval(function() {
      var scrollingElement = $(".product-show-container")[0].ownerDocument.scrollingElement
      if (pageTopPosition != (scrollingElement && scrollingElement.scrollTop)) {
        $(".product-listing-cart-button").attr("disabled", "");
      } else {
        $(".product-listing-cart-button").removeAttr("disabled");
      }
      pageTopPosition = scrollingElement && scrollingElement.scrollTop;
    }, 100);
  }

  // clear quantity input on focus
  $('#api_models_order_item_quantity').focus(() => {
    $('#api_models_order_item_quantity').val('')
  })

  // reset quantity input to all min quantity on blur if empty
  $('#api_models_order_item_quantity').on('blur', (e) => {
    var allMinQuantity = $(".product-price").data('all-minimum-quantity')
    var value = $(e.target).val()
    if (value === 0 || value == undefined || value == "") {
      $('#api_models_order_item_quantity').val(allMinQuantity)
      $(quantityInput).trigger("input")
    }
  })

  // on quantity input, calculate number of matches and update text
  var allListingsCount = $(".product-listing-container").length

  var setMatchesCountTo = function(count) {
    $("#product-listings-matches-count").html(count)
  }

  var setShowMoreMatchesCountTo = function(count) {
    var matchesText = 'Show ' + count + ' more with higher minimum quantity'
    $("#product-listings-more-matches-link").html(matchesText)
  }

  var showMoreMatchesLink = function() {
    $("#product-listings-more-matches-link").show()
    $(".product-listings-matches-container").addClass("-sticky")
  }

  var hideMoreMatchesLink = function() {
    $("#product-listings-more-matches-link").hide()
    $(".product-listings-matches-container").removeClass("-sticky")
  }

  var onShowMoreMatchesClick = function(e) {
    e.preventDefault()

    hideMoreMatchesLink()

    // update matches count
    setMatchesCountTo($(".product-listing-container").length)

    // show hidden matches + their other state
    $(".product-listing-container.-hidden").each((index, elem) => {
      // hide match quantity, total, and button (re-shown elsewhere)
      $(elem).find(".product-listing-quantity-calculated-text").hide()
      $(elem).find(".product-listing-total-price").hide()
      $(elem).find(".product-listing-cart-button").hide()
      // set bg color & show link for minimum quantity
      $(elem).addClass("-less-than-min")
      $(elem).find(".product-listing-min-quantity-link").show()
      // show it
      $(elem).removeClass("-hidden")
    })

    $(".product-listing-variety-heading").show()
  }

  // handle "X lbs min" link -> update quantity input
  $(".product-listing-min-quantity-link").click(function(e) {
    e.preventDefault()

    var linkQuantity = $(e.target).data('quantity')
    $(quantityInput).val(linkQuantity)
    $(quantityInput).trigger("input")
  })

  var handleOnlyChildrenBorder = function() {
    $(".product-listing-variety-list > .product-listing-container").removeClass('-only-child')
    $(".product-listing-variety-list").each((index, elem) => {
      var children = $(elem).children().not('.-hidden')
      if (children.length == 1) {
        $(children).addClass('-only-child')
      } else {
        $(children).removeClass('-only-child')
      }
    })
  }

  // auto-click description '... More' if bigger than mobile
  if ($(window).width() > 599) {
    $(".read-more-link").trigger("click")
  }

  // toggle/update all the things on quantity input change
  $(quantityInput).on('input', function() {
    var newInputValue = $(this).val()

    if (newInputValue) {
      var multipliedPrice, selectedPrice

      $(".product-listing-variety-heading").hide()

      // check for minimum quantity
      if (newInputValue < allOfferingsMinimumQuantity) {
        $(minimumQuantityMessageContainer).addClass('-visible')
        $(".product-listing-container").addClass('-hidden')
        setMatchesCountTo(0)
        setShowMoreMatchesCountTo(allListingsCount)
        showMoreMatchesLink()
        handleOnlyChildrenBorder()
      } else {
        $(minimumQuantityMessageContainer).removeClass('-visible')
        var matchingListingsCount = 0

        $(".product-listing-variety-list").each((_, list) => {
          $(list).children(".product-listing-container").each((index, elem) => {
            var minimumQuantity = $(elem).data('minimum-quantity')

            if (newInputValue >= minimumQuantity) {
              // reset less than min quantity state and show
              $(elem).find(".product-listing-quantity-calculated-text").show()
              $(elem).find(".product-listing-total-price").show()
              $(elem).find(".product-listing-cart-button").show()
              $(elem).removeClass("-less-than-min")
              $(elem).find(".product-listing-min-quantity-link").hide()
              $(elem).removeClass('-hidden')
              // show heading
              var variety = $(elem).closest(".product-listing-variety-list").data('variety')
              $(".product-listing-variety-heading[data-variety='"+variety+"']").show()

              matchingListingsCount++

              // update calculated units, handle max quantity, and set partial quantity
              var quantityCalculatedElem = $(elem).find(".product-listing-quantity-calculated")
              var unit = $(quantityCalculatedElem).data('available-as') // lb, tote, or bag
              var maxQuantity = $(elem).find(".product-listing-cart-button").data("max-quantity")
              var unitSize = $(elem).data("unit-size")
              var maxQuantityAllowed = Math.min(newInputValue, maxQuantity)
              var remainder = maxQuantityAllowed % unitSize
              var toteOrBag = $(elem).data("toteOrBag")
              var toteSize = $(elem).data("tote-size")
              var bagSize = $(elem).data("bag-size")
              var howMany = 0

              // bulk use quantity input (or max) otherwise divide by unit
              if (unit === 'lb') {
                howMany = maxQuantityAllowed
              }else {
                howMany = Math.floor(maxQuantityAllowed / unitSize)
              }

              // if 0, but above minimum quantity determine partial match
              if (howMany == 0 && !toteOrBag) {
                howMany = (maxQuantityAllowed / unitSize)
              // otherwise do partial match if Tote or Bag
              } else if (toteOrBag) {
                var toteCount = Math.floor(maxQuantityAllowed / toteSize)
                var amountAfterTotes = (maxQuantityAllowed - (toteCount * toteSize))
                var bagCount = amountAfterTotes / bagSize
                var remainder = amountAfterTotes - (bagCount * bagSize)
              }

              var howManyContent = ''
              if (toteOrBag && toteCount > 0 && bagCount > 0) {
                howManyContent = `${toteCount} ${pluralize('tote', toteCount)} & ${bagCount} ${pluralize('bag', bagCount)}`
              } else if (toteOrBag && toteCount > 0) {
                howManyContent = `${toteCount} ${pluralize(unit, toteCount)}`
              } else if (toteOrBag && bagCount > 0) {
                howManyContent = `${bagCount} ${pluralize('bag', bagCount)}`
              } else if (newInputValue != maxQuantityAllowed) {
                howManyContent = `<span class="error">${howMany} ${pluralize(unit, howMany)}</span> (max available)`
              } else if (unit.indexOf('lb') >= 0 || remainder == 0) {
                howManyContent = `${howMany} ${pluralize(unit, howMany)}`
              } else {
                howManyContent = `<span class="error">${howMany} ${pluralize(unit, howMany)}</span>&nbsp;·&nbsp;${maxQuantityAllowed - remainder} ${pluralize('lb', (maxQuantityAllowed - remainder))}`
              }

              $(elem).find(".product-listing-quantity-calculated-text").html(howManyContent)
              var addToCartButton = $(elem).find(".product-listing-cart-button")
              $(addToCartButton).data("quantity", (maxQuantityAllowed - remainder))

              // update total
              var totalPriceElem = $(elem).find(".product-listing-total-price")
              var pricePerUnit = $(totalPriceElem).data('price-per-unit')
              var totalPrice = (maxQuantityAllowed - remainder) * pricePerUnit
              $(totalPriceElem).html(`$${totalPrice.toFixed(2).toLocaleString()}`)
            } else {
              $(elem).addClass('-hidden')
            }
          })

          setMatchesCountTo(matchingListingsCount)
          setShowMoreMatchesCountTo(allListingsCount - matchingListingsCount)
          if ((allListingsCount - matchingListingsCount) == 0) {
            hideMoreMatchesLink()
          } else {
            showMoreMatchesLink()
          }
        })
      }

      handleOnlyChildrenBorder()
    } else {
      $(minimumQuantityMessageContainer).removeClass('-visible')
    }
  })

  $(addToCartForm).submit((e) => {
    e.preventDefault(e)
  })

  function addToCartButtonClick(button) {
    if ($(button).prop('disabled') == false) {
      $(button).prop('disabled', true)
      var quantity = $(button).data('quantity')
      var data = {
        api_models_order_item: {
          quantity: quantity,
          listing_id: $(button).data('id')
        }
      }

      $.ajax({
        method: "POST",
        dataType: "json",
        url: addToCartForm.action,
        data: data
      }).done((response) => {
        $(addToCartForm).trigger("reset")
        $($(".view-cart-button")[0]).addClass('-visible')
        $(button).html("Added to Cart")
        showCheckoutFlash()
        $(".cart-count").each(function() {
          $(this).html(response.itemCount);
        });
      }).fail((response) => {
        var errors, errorMessages
        var errorJson = response.responseJSON
        if (errorJson) {
          errors = errorJson.errors
          if (errors.full_messages) {
            errorMessages = errors.full_messages.length > 1 ? errors.full_messages.join(", ") : errors.full_messages
          } else {
            errors = response.responseText
            errorMessages = response.responseText
          }
        } else {
          errors = response.responseText
          errorMessages = response.responseText
        }
        alert("There was an error adding this item to the cart.")
        Sentry.captureException('Error adding item to cart: ' + errorMessages, { contexts: { data: data, errors: errors } })
      })
    }
  }

  // listing row 'Add to Cart' form submit
  $(".product-listing-cart-button").each((index, button) => {
    var buttonDebounce = null
    $(button).on('touchend click', () => {
      clearTimeout(buttonDebounce)
      buttonDebounce = setTimeout(function() {
         addToCartButtonClick(button)
       }, 500)
    })
  })

  init()
})
